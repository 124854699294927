import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isEmpty } from "../../../utils/validate"

const Input = props => {
	const {
		value = "",
		title,
		hint,
		type = "text",
		placeholder,
		onChange,
		showError,
		errorMessage,
		disabled,
		step
	} = props;

	return (
		<div className={`generalInput ${showError === '' ? '' : showError ? 'invalid' : 'valid'} ${isEmpty(step) ? 'rmv-stepper': ''}`}>
			<h5>{title}</h5>
			<div className="hint">{hint}</div>
			<input
				className={`browser-default`}
				type={type}
				placeholder={placeholder}
				value={value}
				disabled={disabled ? true : false}
				onChange={e => {
					onChange(e.target.value);
				}}
			/>
			{showError ? (
				<div className="errorMessage">{errorMessage}</div>
			) : null}
		</div>
	);
};

export default Input;
